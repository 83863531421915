<template>
  <div :class="customClass" :id="elementId" style="min-height: inherit;">
    <div flex-row-center-center style="min-height: inherit">
      <template v-for="(item,index) in formObject.children">

        <template v-if="String(item.href ? item.href:'').trim() !== ''">
          <a :href="String(item.href).trim()" target="_blank" style="box-shadow: 0px 4px 9px 1px rgba(0,0,0,0.0800);">
            <img style="display:block;height: 160px;width: 400px;" :src="normalizeImageUrl(item.src)" alt="">
          </a>
        </template>

        <template v-else>
          <a style="box-shadow: 0px 4px 9px 1px rgba(0,0,0,0.0800);">
            <img style="display:block;height: 160px;width: 400px;" :src="normalizeImageUrl(item.src)" alt="">
          </a>
        </template>

      </template>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

export default {
  // extends   : {},
  mixins    : [],
  components: {},
  name      : 'ChildrenComponentOfOne',
  props     : {
    className : { type: String, default: '' },
    formObject: {
      type   : Object,
      default: () => ({
        label   : '',
        code    : '',
        children: [
          { src: '', href: '' },
        ],
      }),
    },
  },
  data() {
    return {}
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    elementId() {return `ChildrenComponentOfOne_${this._uid}`},
    customClass() {return ['ChildrenComponentOfOne', this.className]},
  },
  methods : {},
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.ChildrenComponentOfOne {

}
</style>
