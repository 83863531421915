<template>
  <div :class="customClass" :id="elementId">
    <div w1200 margin-auto>
      <div flex-row-space-between-center style="position: relative;">
        <cz-home-big-title title="合作伙伴"></cz-home-big-title>
      </div>

      <section style="box-shadow: 0px 5px 15px 1px rgba(0,0,0,0.1000);padding: 40px 40px 0 40px" @mouseenter="mouseEnterSponsor" @mouseleave="mouseLeaveSponsor">
        <div style="display:flex; flex-direction:row; justify-content:space-between; align-items:center">

          <template v-for="(cooperativePartner,index) in cooperativePartnerList">
            <div style="cursor: pointer;padding: 0 30px;position: relative;" @click="activeCode = cooperativePartner.code">
              <span style="font-size: 26px;" :class="activeCode === cooperativePartner.code ?'activated':'not-active'">{{ cooperativePartner.label }}</span>
              <div style="height: 16px;"></div>
              <div v-if="activeCode === cooperativePartner.code" style="position: absolute; right:0; left: 0; bottom: -2px;background-color: #CF000D;height: 4px;"></div>
            </div>
          </template>
        </div>

        <div style="width: 100%;height: 1px;border:1px solid #D8D8D8;"></div>

        <div style="min-height: 370px">
          <component :is="computedComponent" :form-object="currentCooperativePartner"></component>
        </div>
      </section>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

import ChildrenComponentOfFourOrMore from './components/children-component-of-four-or-more.vue'
import ChildrenComponentOfOne        from './components/children-component-of-one.vue'
import ChildrenComponentOfThree      from './components/children-component-of-three.vue'
import ChildrenComponentOfTwo        from './components/children-component-of-two.vue'
import {cloneDeep, isEmpty}          from 'lodash'
import DataConfigMixin               from '@/mixins/data-config-mixin'

export default {
  // extends   : {},
  mixins    : [DataConfigMixin],
  components: {
    ChildrenComponentOfFourOrMore,
    ChildrenComponentOfOne,
    ChildrenComponentOfThree,
    ChildrenComponentOfTwo,
  },
  name      : 'SponsorListComponent',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {
      activeCode: null,
      timer     : null,
      duration  : 5000,
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    computedComponent() {
      if (isEmpty(this.currentCooperativePartner)) {return null}

      if (this.currentCooperativePartner.children.length === 1) {
        return 'ChildrenComponentOfOne'
      }
      else if (this.currentCooperativePartner.children.length === 2) {
        return 'ChildrenComponentOfTwo'
      }
      else if (this.currentCooperativePartner.children.length === 3) {
        return 'ChildrenComponentOfThree'
      }
      else {
        return 'ChildrenComponentOfFourOrMore'
      }
    },
    cooperativePartnerList() {
      return this.dataConfig.cooperativePartnerList
    },
    currentCooperativePartner() {
      let find = this.dataConfig.cooperativePartnerList.find(item => {
        if (item.code === this.activeCode) {
          return item
        }
      })

      if (isEmpty(find)) {
        return null
      }
      else {
        return find
      }
    },
    elementId() {return `SponsorListComponent_${this._uid}`},
    customClass() {return ['SponsorListComponent', this.className]},
  },
  methods : {
    mouseEnterSponsor() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    mouseLeaveSponsor() {
      this.initTimer()
    },
    initTimer() {
      this.timer = setInterval(() => {

        let length = this.cooperativePartnerList.length

        let index = this.cooperativePartnerList.findIndex(item => {
          return item.code === this.activeCode
        })

        if (index === length - 1) {
          this.activeCode = this.cooperativePartnerList[0].code
        }
        else {
          this.activeCode = this.cooperativePartnerList[index + 1].code
        }

      }, this.duration)
    },
  },

  created() {
    // document.documentElement.scrollTop = 0
    this.activeCode = this.cooperativePartnerList[0].code
    this.initTimer()
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  destroyed() {},
  mounted() {
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.SponsorListComponent {
  .activated {
    color : #CF000D;
  }

  .not-active {
    color : #333333;
  }
}
</style>
